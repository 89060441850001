import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

// importing images
import logo1 from './images/logo-1.png'
import logo2 from './images/logo-2.jpg'
import beer1 from './images/main-2.jpeg';
import wineMain from './images/wine-main.jpg';
import beerMain from './images/b-1.jpeg';
import other from './images/w-1.jpg';
import grapes from './images/grapes.jpg';
import wine2 from './images/main.jpg';
import usa from "./images/usa.png";
import arm from './images/arm.jpg';
function App() {
  const [statement,setStatement] = useState(false);
  const [class1,setClass1] = useState(" active");
  const [class2,setClass2] = useState("");
  const [translate,setTranslate] = useState(false);
  const [EN,setEN] = useState([
    "LabCare&Consalting team as an apologist of high quality and safety offers you the key, to have a choice of different yeasts and yeast derivatives from the world leading company.",
    "Fermentis is the best choice for your product’s unique taste and quality.",
    "Your product’s higher quality is in your hands.",
    "Contact us!!",
    "Official Distributor Of Fermentis",
    "wine",
    "beer",
    "spirits",
    "other beverages"
]);
  useEffect(()=>{
    console.log(statement);
    if(statement){
      setClass1(' ');
      setClass2(' active');
    }
    else{
      setClass2(' ');
      setClass1(' active');
    }
  },[statement]);
  useEffect(()=>{
    if(translate){
      let ARM = [
        "ԼաբՔեր&Քոնսալտինգ թիմը իբրև ջատագով որակյալ և անվտանգ արտադրության ընձեռում է Ձեզ հնարավորություն ձեռք բերելու խմորիչներ և խմորիչի ածանցյալներ համաշխարհային լավագուն արտադրողից:",
        "Ֆերմենտիսը  լավագույն ընտրությունն է, որը կապահովի Ձեր արտադրանքի բարձր որակը և յուրահատուկ համը:","Ձեր արտադրանքի բարձր որակը Ձեր ձեռքերում է:",
        "Կապվեք մեզ հետ:",
        "Ֆերմենտիսի պաշտոնական դիստրիբյուտորը ՀՀ-ում",
        "գինի",
        "Գարեջուր",
        "ալկոհոլ",
        "այլ ըմպելիքներ"
      ];
      setEN(ARM);
    }else{
      let ARM = [
        "LabCare&Consalting team as an apologist of high quality and safety offers you the key, to have a choice of different yeasts and yeast derivatives from the world leading company.",
        "Fermentis is the best choice for your product’s unique taste and quality.",
        "Your product’s higher quality is in your hands.",
        "Contact us!!",
        "Official Distributor Of Fermentis",
        "wine",
        "beer",
        "spirits",
        "other beverages"
      ]
      setEN(ARM);
    }
  },[translate])
  const moreController = () =>{
    setStatement(!statement);
  }
  return (
    <div className="main" >
      <div className={"info container "+class1} >
        <div className="row head" >

          <div className="col-md-4 col-xs-6 left-head" >
            <div className="outterCont">
              <div className="innerCont" >
                <img src={logo1} />
              </div>
              <div className="link" >
                <a href='https://fermentis.com/' > <i class="fas fa-link"></i> </a>
              </div>
            </div>
            <div className="outterCont">
              <div className="innerCont" >
                <img src={logo2} className='spec' />
              </div>
              <div className="link" >
                <a href='http://labcare.am/wordpress/' > <i class="fas fa-link"></i> </a>
              </div>
            </div>
          </div>
          <div className='col-md-8 col-xs-6 right-head ' >
            <h3> {EN[4]} </h3>
          </div>
        </div>
        <div className="row body" >
          <div className='text col-sm-12 col-md-5' >
              <div className='inner_text' >

                  <p>{EN[0]}</p>
                  <p>{EN[1]}</p>
                  <p className="special_para" ><i class="fas fa-quote-right"></i> {EN[2]}</p>
                  <p className="special_p" >{EN[3]}</p>
              </div>
              <div className="outer_text" >
              </div>
          </div>
          <div className="image col-sm-12 col-md-7" >
            <img src={beer1} />
          </div>
        </div>
        <div className="row foot" >
            <div className="innerFoot" onClick={moreController} >
              <i class="fas fa-ellipsis-h"></i>
            </div>
        </div>
      </div>
      <div className={"info infospec container "+class2} >
          <div className="row head1" >
            <div className="innerFoot" onClick={moreController} > <i class="fas fa-arrow-left"></i> </div>
          </div>
          <div className="row body2" >
            <div className="col-md-5 col-sm-12 body1" >
              <div className='col-12 contact-col ' >
                <div className="left-part" ><i class="fas fa-map-marker-alt"></i></div>
                <div className="right-part"><p>Fuchik 13 </p></div>
              </div>
              <div className='col-12 contact-col ' >
                <div className="left-part" ><i class="fas fa-phone-alt"></i></div>
                <div className="right-part"><p> +37477473021 </p></div>
              </div>
              <div className='col-md-12 col-sm-12 contact-col ' >
                <div className="left-part" ><i class="fas fa-envelope"></i></div>
                <div className="right-part"><p> labcarecons@gmail.com </p></div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 four-cont " >
              <div className="cont" >
                <img src={wineMain} />
                <div className="innerConte">
                  <p>{EN[5]}</p>
                </div>
              </div>
              <div className="cont" >
                <img src={beerMain} />
                <div className="innerConte">
                  <p>{EN[6]}</p>
                </div>
              </div>
              <div className="cont" >
                <img src={grapes} />
                <div className="innerConte">
                  <p>{EN[7]}</p>
                </div>
              </div>
              <div className="cont" >
                <img src={other} />
                <div className="innerConte">
                  <p>{EN[8]}</p>
                </div>
              </div>

            </div>
      </div>
    </div>
      <div className="back" >
        <div className="centerImg" >
          <img src={wine2} />
        </div>
      </div>
      <div className="translator" >
        <div className="lang" onClick={()=>{setTranslate(true);}} ><img src={arm} /></div>
        <div className="lang" onClick={()=>{setTranslate(false);}}><img src={usa} /></div>
      </div>
    </div>
  );
}

export default App;
